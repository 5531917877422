import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ToastService } from '@core/services';

import { ProfileStateModel, ProfileActions } from './index';

import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProfileService } from '@core/services/profile/profile.service';
const defaults: ProfileStateModel = {
  email: '',
  firstName: '',
  lastName: '',
  profileImage: '',
  companyName: '',
  vatNumber: '',
  gender: '',
  latitude: '',
  longitude: '',
  street: '',
  zipCode: '',
  houseNumber: '',
  city: '',
  isKycVerified: false,
  language: [],
  education: [],
  industries: [],
  certificate: [],
  reference: [],
  defaultList: {
    language: [],
    education: [],
    industry: []
  }
};
@State<ProfileStateModel>({
  name: 'profile',
  defaults: {
    email: '',
    firstName: '',
    lastName: '',
    profileImage: '',
    companyName: '',
    vatNumber: '',
    gender: '',
    latitude: '',
    longitude: '',
    street: '',
    zipCode: '',
    houseNumber: '',
    city: '',
    isKycVerified: false,
    language: [],
    education: [],
    industries: [],
    certificate: [],
    reference: [],
    defaultList: {
      language: [],
      education: [],
      industry: []
    }
  }
})
@Injectable()
export class ProfileState {
  constructor(private _profileService: ProfileService, private _toastService: ToastService) { }

  @Selector()
  static profile(state: ProfileStateModel) {
    return state;
  }

  @Action(ProfileActions.GetProfile)
  getProfile({ patchState }: StateContext<ProfileStateModel>) {
    return this._profileService.getProfile().pipe(
      tap(_ => {
        patchState(_.data);
      })
    );
  }

  @Action(ProfileActions.UpdateProfile)
  updateProfile(
    { patchState }: StateContext<ProfileStateModel>,
    { payload }: ProfileActions.UpdateProfile
  ) {
    return this._profileService.updateProfile(payload).pipe(
      tap(_ => {
        patchState(_.data);
      })
    );
  }

  @Action(ProfileActions.GetDefaultList)
  getDefaultList(
    { patchState }: StateContext<ProfileStateModel>
  ) {
    return this._profileService.getDefaultList().pipe(
      tap(_ => {
        patchState({ defaultList: _.data });
      })
    );
  }

  @Action(ProfileActions.ResetProfile)
  logout(context: StateContext<ProfileStateModel>) {
    context.setState({ ...defaults });
  }
}


