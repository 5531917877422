import { OfferFilter } from './offers.model';

export namespace OffersActions {
  export class MakeOffer {
    static readonly type = '[Offers] Create Offer';
    constructor(public payload: Record<string, any>) { }
  }
  export class EditOffer {
    static readonly type = '[Offers] Edit Offer';
    constructor(public payload: Record<string, any>) { }
  }
  export class GetOffers {
    static readonly type = '[Jobs] Get Offers List';
    constructor(public payload: { type: string; filter: OfferFilter }) { }
  }

  export class GetAllOffers {
    static readonly type = '[Jobs] Get All Offers List';
    constructor() { }
  }
}
