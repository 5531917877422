import { Action, Selector, State, StateContext } from '@ngxs/store';
import { OffersStateModel, OffersActions, OfferFilter } from './index';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OffersService } from '@core/services/offers/offers.service';
import { EOfferTypes } from '@core/constants';

@State<OffersStateModel>({
  name: 'offers',
  defaults: {
    result: null,
    received: [],
    receivedOffersCount: 0,
    given: [],
    givenOffersCount: 0,
    allOpenFiltered: []
  }
})
@Injectable()
export class OffersState {
  constructor(private _offersService: OffersService, private _http: HttpClient) { }

  @Selector()
  static offers(state: OffersState) {
    return state;
  }

  @Action(OffersActions.MakeOffer)
  makeOffer(
    { patchState }: StateContext<OffersStateModel>,
    { payload }: OffersActions.MakeOffer
  ) {
    const body: Record<string, any> = Object.assign({}, payload);

    for (const key in body) {
      if (!!key && !!body[key]) {

        !!key.match(/Time/g) && (body[key] = body[key].includes('T') ? body[key]?.split('T')[1]?.split('-')[0].substring(0, 5) : body[key]);
        !!key.match(/Date/g) && (body[key] = body[key]?.split('T')[0]);
      }
    }


    return this._offersService.createOffer(body).pipe(
      tap((_: Record<string, any>) => patchState({ result: _ })),
      catchError((err: Record<string, any>) => { throw err.error; })
    );
  }

  @Action(OffersActions.EditOffer)
  editOffer(
    { patchState }: StateContext<OffersStateModel>,
    { payload }: OffersActions.EditOffer
  ) {
    const body: Record<string, any> = Object.assign({}, payload);

    for (const key in body) {
      if (!!key && !!body[key]) {

        !!key.match(/Time/g) && (body[key] = body[key].includes('T') ? body[key]?.split('T')[1]?.split('-')[0].substring(0, 5) : body[key]);
        !!key.match(/Date/g) && (body[key] = body[key]?.split('T')[0]);
      }
    }


    return this._offersService.editOffer(body).pipe(
      tap((_: Record<string, any>) => patchState({ result: _ })),
      catchError((err: Record<string, any>) => { throw err.error; })
    );
  }

  @Action(OffersActions.GetAllOffers)
  getAllOffers({ patchState }: StateContext<OffersStateModel>,
  ) {
    return this._offersService.getAllOffers().pipe(
      tap(_ => {
        patchState({
          receivedOffersCount: _.received.data?.totalRowCount || 0,
          givenOffersCount: _.given.data?.totalRowCount || 0,
        });
      })
    );
  }

  @Action(OffersActions.GetOffers)
  getOffersByTypeAndFilter({ patchState }: StateContext<OffersStateModel>,
    { payload }: OffersActions.GetOffers) {
    let filterStr = '';
    if (payload.filter) {
      filterStr = composeFilter(payload.filter);
    }
    return this._offersService.getOffers(payload.type, filterStr).pipe(
      tap(_ => {

        if (payload.type === EOfferTypes.received) {
          patchState({ [payload.type]: _.data.rows, receivedOffersCount: _.data.totalRowCount });
        } else if (payload.type === EOfferTypes.given) {
          patchState({ [payload.type]: _.data.rows, givenOffersCount: _.data.totalRowCount });
        }

      })
    );
  }
}

export const composeFilter = (filter: OfferFilter) => {
  let queryString = '';
  // all-draft?count=20&page=1&filter[priceFrom]=100&filter[priceTo]=400
  const keylen = Object.keys(filter).length;
  Object.keys(filter).forEach((element, index) => {
    if (index === 0) {
      queryString += '?';
    }
    queryString += element + '=' + filter[element];
    if (index !== keylen - 1) {
      queryString += '&';
    }
  });
  return queryString;
};


