import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '@core/constants';
import { ListResponseData } from '@core/models/rating.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';

@Injectable({
    providedIn: 'root'
})
export class RatingService {

    constructor(private _apiService: ApiService) { }

    getRatingsList(req: { type: string; userId: string; count?: number; page?: number }): Observable<ListResponseData> {
        if (!req.count) {
            req.count = 10;
        }
        if (!req.page) {
            req.page = 1;
        }
        const url = `${API_ENDPOINTS.ratingList}${req.type}\\${req.userId}?count=${req.count}&page=${req.page}`;
        return this._apiService.get(url).pipe(map(res => res.data));
    }

    createRating(data: { ratingValue: number; comment: string; job: string }) {
        return this._apiService.post(API_ENDPOINTS.rating, data);

    }

}

