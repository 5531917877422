import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Message } from '@core/models/firebasedata.model';

import { Firestore, collectionData, collection, query, where, getDocs, CollectionReference, addDoc } from '@angular/fire/firestore';
@Injectable({
    providedIn: 'root',
})
export class MessageService {
    private messageCollection: any;
    message$: Observable<Message[]>;

    constructor(private afs: Firestore) {
        this.messageCollection = collection(afs, 'messages');
        this.message$ = collectionData(this.messageCollection);
    }

    addMessage(data: Message) {
        const ref = collection(this.afs, 'messages');
        return addDoc(ref, data);

    }

    getMessages(data?: { fromUserId?: string; userId: string }): Observable<Message[]> {

        const ref = collection(this.afs, 'messages',);
        return collectionData(ref) as Observable<Message[]>;
    }

    getMessageRef() {
        return collection(this.afs, 'messages');
    }


    async subCollection(data: { fromUserId?: string; userId: string }) {
        if (data.fromUserId && data.userId) {
            const q = query(collection(this.afs, 'messages'), where('fromUserId', '==', data.fromUserId),
                where('userId', '==', data.userId));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, ' => ', doc.data());
            });

            // return this.message$;
        }
        else if (data.userId) {
            const q = query(collection(this.afs, 'messages'),

                where('userId', '==', data.userId));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log('dd', doc.id, ' => ', doc.data());
            });

            // return this.message$;
        }
    }


}
