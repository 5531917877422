
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { User } from '@core/models/firebasedata.model';


import { Firestore, collectionData, collection, setDoc, doc } from '@angular/fire/firestore';
@Injectable({
    providedIn: 'root',
})
export class UserService {
    private userPostsCollection: any;
    userPosts$: Observable<User[]>;

    constructor(private firestore: Firestore) {
        this.userPostsCollection = collection(firestore, 'users');
        this.userPosts$ = collectionData(this.userPostsCollection);
    }

    async addUser(data: { userId: string; name: string; profileImageFullPath?: string }) {
        const ref = collection(this.firestore, 'users');
        await setDoc(doc(this.firestore, 'users', data.userId), data);
        //return addDoc(ref, data);
    }

    getUsers(): Observable<User[]> {
        return this.userPosts$;
    }

    getUserRef() {
        return collection(this.firestore, 'users');
    }
}


