
export class ProfileStateModel {
  email: string;
  firstName: string;
  lastName: string;
  profileImage: string | any;
  companyName: string;
  vatNumber: string;
  gender: string;
  latitude: string;
  longitude: string;
  street: string;
  zipCode: string;
  houseNumber: string;
  city: string;
  isKycVerified?: boolean;
  language: string[] | any;
  education: string[] | any;
  industries: string[] | any;
  certificate: string[] | any;
  reference: string[] | any;
  defaultList: IDefaultList;
  [propName: string]: any;
  deviceIds?: string[];
}

export interface IDefaultList {
  language?: IDefaultListItem[];
  education?: IDefaultListItem[];
  industry?: IDefaultListItem[];
  industriesType?: IDefaultListItem[];
}

export interface IDefaultListItem {
  id: string;
  name?: string;
}
export class Report {
  user: string;
  message: string;
  reason: number;
}