import { ProfileStateModel } from './profile.model';

export namespace ProfileActions {
  export class GetProfile {
    static readonly type = '[Profile] Get Profile details';
    constructor(public payload: { userId: string }) { }
  }

  export class UpdateProfile {
    static readonly type = '[Profile] Update Profile Details';
    constructor(public payload: ProfileStateModel) { }
  }

  export class GetDefaultList {
    static readonly type = '[Profile] Get Default list like Language, Education, Industries etc';
    constructor() { }
  }

  export class ResetProfile {
    static readonly type = '[Profile] Get Profile details';
    constructor(public payload: { userId: string }) { }
  }
}
