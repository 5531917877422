export class OfferRequest {
  priceOffered: number;
  message: string;
  firstDateProposed: string;
  secondDateProposed: string;
  isAccepted: boolean;
  job: string;
}

export class OffersStateModel {

  result?: Record<string, any>;
  received: any;
  given: any;
  allOpenFiltered: any;
  receivedOffersCount: number;
  givenOffersCount: number;
}

export class OfferFilter {
  count?: string;
  page?: string;
  type?: string;
}


