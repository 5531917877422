import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '@core/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JobDetailsModel } from 'src/app/pages/job-detail/models/jobDetail.models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FavouriteJobService {

  constructor(private _apiService: ApiService) { }

  addToFavouriteJobs(data: { job: string }) {
    const url = API_ENDPOINTS.favouriteJob;
    return this._apiService.post(url, data);
  }

  getFavouriteJobs(): Observable<JobDetailsModel>  {
    const url = API_ENDPOINTS.favouriteJobListing;
    return this._apiService.get(url).pipe(map(res => res.data));
}

}

export class JobDetailResponse {
  data: JobDetailsModel;
  error: boolean;
};
