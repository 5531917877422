import { Injectable } from '@angular/core';
import { ApiService } from '.././api.service';
import { API_ENDPOINTS, APP_CONSTANT } from '@core/constants';
import { forkJoin, Observable } from 'rxjs';



@Injectable({
    providedIn: 'root'
})
export class OffersService {

    constructor(private _apiService: ApiService) { }

    /**
   * Function to get Offers
   *
   * @param type  one of the following received sent
   * @param filterstring  composed string from page pageno and filters
   */

    getOffers(type: string, filterString: string): Observable<OffersResponse> {
        const url = `${API_ENDPOINTS.offers}/${type}${filterString}`;
        return this._apiService.get(url, true);
    }

    getAllOffers(): Observable<AllOffersResponse> {
        const receivedOfferUrl = `${API_ENDPOINTS.offers}/received`;
        const sentOfferUrl = `${API_ENDPOINTS.offers}/given`;
        return forkJoin({
            received: this._apiService.get(receivedOfferUrl, true, `${APP_CONSTANT.offers}_received`),
            given: this._apiService.get(sentOfferUrl, true, `${APP_CONSTANT.offers}_given`)
        });

    }

    createOffer(body) {
        const url = `${API_ENDPOINTS.makeOffer}`;
        return this._apiService.post(url, body);
    }
    editOffer(body) {
      const url = `${API_ENDPOINTS.editOffer}`;
      return this._apiService.post(url, body);
  }

    getOfferDetails(id: string) {
        const url = `${API_ENDPOINTS.offerDetails}${id}`;
        return this._apiService.get(url);
    }


    acceptOffer(offerObj) {

        return this._apiService.patch(API_ENDPOINTS.acceptOffer, offerObj);

    }

    rejectOffer(offerObj) {

      return this._apiService.patch(API_ENDPOINTS.rejectOffer, offerObj);

  }

    completeOffer(jobId: string, type: number) {


        const reqObj = {
            status: type === 1 ? 'JOB_COMPLETED_BY_CONTRACTOR' :
                'JOB_ACCEPTED_BY_CREATOR'
        };
        return this._apiService.put('/job/' + jobId + API_ENDPOINTS.changeStatus, reqObj);

    }


}

export class OffersResponse {
    data: Record<string, any>[] | any;
    error: boolean;
};

export class AllOffersResponse {
    received: OffersResponse; given: OffersResponse;
}

