import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '@core/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    constructor(private _apiService: ApiService) { }

    downloadFile(path): Observable<any> {
        const pathObj = {
            path
        };
        const url = API_ENDPOINTS.download;
        return this._apiService.post(url, pathObj).pipe(map(res => res.data));
    }

}
export class DownloadDoc {
    mimeType: string;
    document: string;
}


export class JobDetailResponse {
    data: DownloadDoc;
    error: boolean;
};
