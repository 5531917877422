import { Injectable } from '@angular/core';
import { ApiService } from '.././api.service';
import { API_ENDPOINTS } from '@core/constants';
import { Observable } from 'rxjs';
import { ListResponseAny } from '@core/models/rating.model';
import { JobResponseModel } from '@store/jobs/jobs.model';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    constructor(private _apiService: ApiService) { }


    getJobSearch(searchText?: string, filter?: any, sort?: string, sortOrder?: string, count?: string, page?: string):
        Observable<JobResponseModel> {

        return this._apiService.post(`${API_ENDPOINTS.searchText}`, {
            searchText, filter, sort, sortOrder, count, page
        });
    }

    getCatSearch(searchText?: string): Observable<ListResponseAny> {

        return this._apiService.post(API_ENDPOINTS.categorySearch, { term: searchText });
    }

    getCatJobSearch(uuid?: string, filter?: any, sort?: string, sortOrder?: string, count?: string, page?: string):
        Observable<JobResponseModel> {

        return this._apiService.post(API_ENDPOINTS.categoryJob, {
            uuid, filter, sort, sortOrder, count, page
        });
    }
}
